<template>
  <div class="dashboard-container">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formData.userName" style="width: 50%" />
        </el-form-item>

        <el-form-item label="账号" prop="account">
          <el-input v-model="formData.account" style="width: 50%" />
        </el-form-item>
        <el-form-item label="头像" prop="headlogo">
          <el-upload
            class="avatar-uploader"
            action="http://up-z0.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addManagerUser"
            >添加平台账号</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="userName"
          label="用户名"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="account"
          label="账号"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="userRole"
          label="角色"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.userRole == 1 ? '管理员' : '普通用户' }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="updateManagerUser(scope.row)"
            >
              编辑
            </el-button>
            <el-button type="text" size="small" @click="cz(scope.row)">
              密码重置
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delManagerUser(scope.row)"
              v-if="scope.row.accountLevel !== 2"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getManagerUserList"
      />
    </div>
  </div>
</template>
  
  <script>
import {
  resetManagerPassword,
  getUploadToken,
  getManagerUserList,
  delManagerUser,
  addManagerUser,
  updateManagerUser
} from '../../request/http'

export default {
  data() {
    return {
      title: '',
      showDialog: false,
      list: '',
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10
      },
      imageUrl: '',
      postData: {
        token: '',
        key: '' //上传的Logo
      },
      dialogVisible: false, //显示添加或编辑框
      formData: {
        userName: '',
        account: '',
        headLogo: ''
      },

      rules: {
        userName: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],

        account: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
        ]
      }
    }
  },

  created() {
    this.getManagerUserList()
  },

  mounted() {},
  methods: {
    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log('上传成功', res, file)
      this.imageUrl = 'http://file.innopinenut.com/' + res.key
      this.formData.headLogo = res.key
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken()
      // console.log(data);
      if (data == '' || data == null) {
        this.message.error('上传图片时获取Token失败！')
        return
      }
      this.postData.token = data.token
      this.postData.key = data.fid
    },
    // 返回上一级页面
    goBack() {
      this.$router.go(-1)
    },
    async cz(row) {
      try {
        await this.$confirm('确定要重置此账号吗?')
        console.log(row)
        let { code, msg } = await resetManagerPassword({
          userId: row.userId
        })

        if (code == 0) {
          this.$message({
            type: 'success',
            message: '重置成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: msg
          })
        }

        this.getManagerUserList()
      } catch (error) {
        console.log(error)
      }
    },

    //获取列表
    async getManagerUserList() {
      this.queryParams.companyId = this.companyId
      let { data } = await getManagerUserList(this.queryParams)
      // let a= data.filter(item=>{
      //    return item.accountLevel!==2
      //  })
      //  console.log(a);
      this.list = data
      // console.log(this.list)

      this.total = data.length
    },

    // 打开添加的窗口
    addManagerUser() {
      this.title = '添加平台管理员账号'

      this.showDialog = true
    },
    // 打开编辑类别的窗口
    updateManagerUser(val) {
      // 根据获取id获取详情
      this.title = '编辑平台管理员账号'
      val.headLogo? this.imageUrl = 'http://file.innopinenut.com/' + val.headLogo :""
      console.log(val)
      this.formData = {
        userId: val.userId,
        userName: val.userName,
        account: val.account,
        headLogo: val.headLogo
      }
      this.showDialog = true
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
        if (this.formData.userId) {
          let { code, msg } = await updateManagerUser(this.formData)
          if (this.formData.userId && code == 0) {
            this.$message.success('编辑成功')
          } else {
            console.log(msg)
            this.$message({
              type: 'error',
              message: msg
            })
          }
          this.getManagerUserList()

          this.showDialog = false
        } else {
          let { code, msg } = await addManagerUser(this.formData)
          console.log(code, msg)
          if (code == 0) {
            this.$message.success('新增成功')
          } else {
            console.log(msg)
            this.$message({
              type: 'error',
              message: msg
            })
          }
          this.getManagerUserList()

          this.showDialog = false
        }
      })
    },
    //弹窗取消函数
    btnCancel() {
      this.imageUrl = ''
      this.formData = {}
      this.$refs.perForm.resetFields()
      this.showDialog = false
    },

    async delManagerUser(row) {
      try {
        await this.$confirm('确定要删除该账号吗')
        console.log(row)
        let { code, msg } = await delManagerUser({
          userId: row.userId
        })

        if (code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
        } else {
          this.$message({
            type: 'error',
            message: msg
          })
        }
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        )
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page

        this.getManagerUserList()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
  
  <style scoped>
</style>
  